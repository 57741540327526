<template>
  <div>
    <b-card no-body>
      <b-card-header class="pt-0 pb-1 d-flex align-items-center">
        <b-button
          size="sm"
          variant="flat-secondary"
          class="btn-icon rounded-circle"
          style="padding: 0.5rem 10px !important"
          :disabled="isNotSelectedRows"
        >
          <unicon name="trash-alt" width="18"></unicon>
        </b-button>
        <b-button
          v-if="type == 2"
          size="md"
          variant="primary"
          class="mr-1"
          to="/subjects-units/units"
        >
          وحدة جديدة
        </b-button>
      </b-card-header>
      <b-card-body class="px-0">
        <vue-good-table
          @on-selected-rows-change="selectionChanged"
          :columns="columns[+type - 2]"
          :rows="activeTableDetails"
          :rtl="false"
          :small="true"
          styleClass="vgt-table condensed"
          ref="accounts-details-table"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <span class="text-nowrap">{{
                props.row.firstName + " " + props.row.lastName
              }}</span>
            </span>
            <span v-else-if="props.column.field === 'createdAt'">
              {{ new Date(props.row.createdAt).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'publishTime'">
              {{ new Date(props.row.publishTime).toISOString().substr(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'dateAsked'">
              {{ new Date(props.row.dateAsked).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'dateAnswered'">
              {{ new Date(props.row.dateAnswered).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'dateCreated'">
              {{ new Date(props.row.dateCreated).toISOString().substr(0, 10) }}
            </span>
            <span v-else-if="props.column.field === 'dateActivated'">
              {{
                  !!props.row.dateActivated
                  ? new Date(props.row.dateActivated).toISOString().substr(0, 10)
                  : "لا يوجد"
              }}
            </span>
            <span v-else-if="props.column.field === 'dateBlocked'">
              <b-badge
                class="px-1"
                :variant="
                  props.row.dateBlocked == null
                    ? 'light-success'
                    : 'light-warning'
                "
              >
                {{ props.row.dateBlocked == null ? "مفعل" : "غير مفعل" }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'details'">
              <b-button
                :to="type == 2 ? `/subjects-units/units/details/${props.row.id}`  : 
                     ``"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: 2px 6px !important"
              >
                <unicon name="ellipsis-v" width="18"></unicon>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
    <b-col cols="12" class="d-flex justify-content-center">
      <b-pagination
        :value="detailsFirstPage"
        :total-rows="currentList.length"
        :per-page="detailsPageLength"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mt-0 mb-0"
        @input="(value) => updateDetailsPagination(value)"
      >
        <template #prev-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
        <template #next-text>
          <unicon width="20" name="angle-right" fill="royalblue" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BBadge,
  },
  methods: {
    ...mapActions([
      "updateDetailsPagination",
      "fetchTeacherUnits",
      "fetchVideoAnswers",
      "fetchPosCodes",
      "fetchStudentCodes",
    ]),
    selectionChanged() {
      this.isNotSelectedRows = !this.$refs["accounts-details-table"].selectedRows.length;
    },
    getSelectedRow() {
      console.log(this.$refs["accounts-details-table"].selectedRows);
    },
  },
  created() {
    switch (+this.type) {
      case 2:
        this.fetchTeacherUnits(+this.userId);
        break;
      case 3:
        this.fetchVideoAnswers(+this.userId);
        break;
      case 4:
        this.fetchPosCodes(+this.userId);
        break;
      case 5:
        this.fetchStudentCodes(+this.userId);
        break;
    }
  },
  data() {
    return {
      isNotSelectedRows: true,
      columns: [
        [
          {
            label: "اسم الوحدة",
            field: "name",
            sortable: true,
          },
          {
            label: "اسم المادة",
            field: "subjectName",
            sortable: true,
          },
          {
            label: "تاريخ الإنشاء",
            field: "publishTime",
            sortable: true,
          },
          {
            label: "عدد الاشتراكات",
            field: "subscriptionsCount",
            sortable: true,
          },
          {
            label: "عدد الدروس",
            field: "lessonsCount",
            sortable: true,
          },
          {
            label: "تفاصيل",
            field: "details",
            sortable: false,
          },
        ],
        [
          {
            label: "اسم المادة",
            field: "subjectName",
            sortable: true,
          },
          {
            label: "اسم الدرس",
            field: "lessonName",
            sortable: true,
          },
          {
            label: "السؤال",
            field: "question",
            sortable: true,
          },
          {
            label: "الإجابة",
            field: "answer",
            sortable: true,
          },
          {
            label: "اسم الطالب",
            field: "studentName",
            sortable: true,
          },
          {
            label: "تاريخ السؤال",
            field: "dateAsked",
            sortable: true,
            type: Date,
          },
          {
            label: "تاريخ الإجابة",
            field: "dateAnswered",
            sortable: true,
            type: Date,
          },
        ],
        [
          {
            label: "الرمز",
            field: "code",
          },
          {
            label: "تاريخ الإنشاء",
            field: "dateCreated",
            sortable: false,
          },
          {
            label: "تاريخ التفعيل",
            field: "dateActivated",
            sortable: false,
          },
          {
            label: "القيمة المدفوعة",
            field: "price",
            type: "number",
            thClass: "vgt-left-align sortable",
            tdClass: "vgt-left-align",
            sortable: true,
          },
        ],
        [
          {
            label: "الرمز",
            field: "code",
          },
          {
            label: "تاريخ التفعيل",
            field: "dateActivated",
            sortable: false,
          },
          {
            label: "اسم المندوب",
            field: "posName",
          },
          {
            label: "القيمة المدفوعة",
            field: "price",
            type: "number",
            thClass: "vgt-left-align sortable",
            tdClass: "vgt-left-align",
            sortable: true,
          },
        ],
      ],
    };
  },
  props: {
    type: String,
    userId: String,
  },
  computed: {
    ...mapGetters([
      "posCodesList",
      "studentCodesList",
      "teacherUnitsList",
      "responderVideoAnswersList",
      "activeTableDetails",
      "detailsFirstPage",
      "detailsPageLength",
      "currentList",
    ]),
  },
};
</script>
