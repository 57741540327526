var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pt-0 pb-1 d-flex align-items-center"},[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"0.5rem 10px !important"},attrs:{"size":"sm","variant":"flat-secondary","disabled":_vm.isNotSelectedRows}},[_c('unicon',{attrs:{"name":"trash-alt","width":"18"}})],1),(_vm.type == 2)?_c('b-button',{staticClass:"mr-1",attrs:{"size":"md","variant":"primary","to":"/subjects-units/units"}},[_vm._v(" وحدة جديدة ")]):_vm._e()],1),_c('b-card-body',{staticClass:"px-0"},[_c('vue-good-table',{ref:"accounts-details-table",attrs:{"columns":_vm.columns[+_vm.type - 2],"rows":_vm.activeTableDetails,"rtl":false,"small":true,"styleClass":"vgt-table condensed","select-options":{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.firstName + " " + props.row.lastName))])]):(props.column.field === 'createdAt')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.createdAt).toISOString().substr(0, 10))+" ")]):(props.column.field === 'publishTime')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.publishTime).toISOString().substr(0, 10))+" ")]):(props.column.field === 'dateAsked')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.dateAsked).toISOString().substr(0, 10))+" ")]):(props.column.field === 'dateAnswered')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.dateAnswered).toISOString().substr(0, 10))+" ")]):(props.column.field === 'dateCreated')?_c('span',[_vm._v(" "+_vm._s(new Date(props.row.dateCreated).toISOString().substr(0, 10))+" ")]):(props.column.field === 'dateActivated')?_c('span',[_vm._v(" "+_vm._s(!!props.row.dateActivated ? new Date(props.row.dateActivated).toISOString().substr(0, 10) : "لا يوجد")+" ")]):(props.column.field === 'dateBlocked')?_c('span',[_c('b-badge',{staticClass:"px-1",attrs:{"variant":props.row.dateBlocked == null
                  ? 'light-success'
                  : 'light-warning'}},[_vm._v(" "+_vm._s(props.row.dateBlocked == null ? "مفعل" : "غير مفعل")+" ")])],1):(props.column.field === 'details')?_c('span',[_c('b-button',{staticClass:"btn-icon rounded-circle",staticStyle:{"padding":"2px 6px !important"},attrs:{"to":_vm.type == 2 ? ("/subjects-units/units/details/" + (props.row.id))  : 
                   "","size":"sm","variant":"flat-secondary"}},[_c('unicon',{attrs:{"name":"ellipsis-v","width":"18"}})],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"cols":"12"}},[_c('b-pagination',{staticClass:"mt-0 mb-0",attrs:{"value":_vm.detailsFirstPage,"total-rows":_vm.currentList.length,"per-page":_vm.detailsPageLength,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return _vm.updateDetailsPagination(value); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('unicon',{attrs:{"width":"20","name":"angle-right","fill":"royalblue"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }