<template>
  <div>
    <validation-observer ref="editAccountForm">
      <b-form>
        <b-row>
          <b-col cols="12" lg="12">
            <b-card no-body>
              <b-card-header>
                <div>
                  <unicon name="user" fill="#5E5873" width="50" height="30"></unicon>
                  <strong>معلومات المستخدم</strong>
                </div>
                <div
                  v-if="
                    userDto.userType == 3 ||
                    userDto.userType == 4 ||
                    userDto.userType == 5
                  "
                >
                  <strong class="mr-1">حالة الحساب</strong>
                  <b-badge
                    class="px-1"
                    :variant="
                      userDto.dateBlocked == null ? 'light-success' : 'light-warning'
                    "
                  >
                    {{ userDto.dateBlocked == null ? "مفعل" : "غير مفعل" }}
                  </b-badge>
                </div>
              </b-card-header>
              <b-card-body>
                <div class="">
                  <b-row>
                    <b-col lg="2" md="6" sm="12">
                      <b-form-group label="الاسم" label-for="detailsfirstName">
                        <validation-provider
                          #default="{ errors }"
                          name="الاسم"
                          rules="required"
                        >
                          <b-form-input
                            id="detailsfirstName"
                            placeholder="الاسم"
                            v-model="userDto.firstName"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="2" md="6" sm="12">
                      <b-form-group label="الكنية" label-for="detailslastName">
                        <validation-provider
                          #default="{ errors }"
                          name="الكنية"
                          rules="required"
                        >
                          <b-form-input
                            id="detailslastName"
                            placeholder="الكنية"
                            v-model="userDto.lastName"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="اسم المستخدم" label-for="detailsusername">
                        <validation-provider
                          #default="{ errors }"
                          name="اسم المستخدم"
                          rules="required|no_spaces|english_letters_numbers"
                        >
                          <b-form-input
                            id="detailsusername"
                            placeholder="اسم المستخدم"
                            v-model="userDto.username"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="رقم الهاتف" label-for="detailsphoneNumber">
                        <validation-provider
                          #default="{ errors }"
                          name="رقم الهاتف"
                          rules="required"
                        >
                          <b-form-input
                            id="detailsphoneNumber"
                            placeholder="رقم الهاتف"
                            v-model="userDto.phoneNumber"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="البريد الالكتروني" label-for="detailsemail">
                        <validation-provider
                          #default="{ errors }"
                          name="البريد الالكتروني"
                          rules="email"
                        >
                          <b-form-input
                            id="detailsemail"
                            placeholder="البريد الالكتروني"
                            v-model="userDto.email"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="كلمة السر" label-for="detailspassword">
                        <validation-provider
                          #default="{ errors }"
                          name="كلمة السر"
                          rules="required"
                        >
                          <b-form-input
                            type="password"
                            id="detailspassword"
                            placeholder="كلمة السر"
                            v-model="userDto.password"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="تاريخ الميلاد" label-for="detailsdob">
                        <validation-provider
                          v-slot="{ errors }"
                          name="تاريخ الميلاد"
                          rules="required"
                        >
                          <flat-pickr
                            id="detailsdob"
                            v-model="userDto.dob"
                            class="form-control"
                            :config="config"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12">
                      <b-form-group label="تاريخ الاشتراك" label-for="detailscreatedAt">
                        <validation-provider
                          v-slot="{ errors }"
                          name="تاريخ الاشتراك"
                          rules="required"
                        >
                          <flat-pickr
                            id="detailscreatedAt"
                            v-model="userDto.createdAt"
                            class="form-control"
                            disabled
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4" md="6" sm="12" v-if="userDto.userType == 4">
                      <b-form-group label="العنوان" label-for="detailsposAddress">
                        <validation-provider
                          #default="{ errors }"
                          name="العنوان"
                          rules="required"
                        >
                          <b-form-input
                            v-model="userDto.posAddress"
                            id="detailsposAddress"
                            placeholder="العنوان"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col v-if="userDto.userType == 4" lg="1" md="6" sm="12">
                      <b-form-group label="الحد المالي" label-for="detailsmoneyLimit">
                        <validation-provider
                          #default="{ errors }"
                          name="الحد المالي"
                          rules="required"
                        >
                          <b-form-input
                            v-model="userDto.moneyLimit"
                            id="detailsmoneyLimit"
                            placeholder="الحد المالي"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      lg="1"
                      md="6"
                      sm="12"
                      v-if="
                        userDto.userType == 2 ||
                        userDto.userType == 3 ||
                        userDto.userType == 4
                      "
                    >
                      <b-form-group
                        :label="userDto.userType == 3 ? 'قيمة السؤال' : 'النسبة'"
                        label-for="detailsrate"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="النسبة"
                          rules="required"
                        >
                          <b-form-input
                            v-model="userDto.rate"
                            id="detailsrate"
                            placeholder="rate"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      lg="1"
                      md="6"
                      sm="12"
                      v-if="
                        userDto.userType == 2 ||
                        userDto.userType == 3 ||
                        userDto.userType == 4 ||
                        userDto.userType == 5
                      "
                    >
                      <b-form-group
                        :label="
                          userDto.userType == 2
                            ? 'عدد الوحدات'
                            : userDto.userType == 3
                            ? 'عدد الإجابات'
                            : userDto.userType == 4
                            ? 'عدد الرموز المباعة'
                            : 'عدد الاشتراكات'
                        "
                        label-for="detailscount"
                      >
                        <b-form-input
                          id="detailscount"
                          placeholder=""
                          v-model="userDto.count"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="4" md="6" sm="12" v-if="userDto.userType == 3">
                      <b-form-group label="المواد" label-for="detailssubjectId">
                        <!-- <validation-provider
              #default="{ errors }"
              name="المواد"
              rules="required"
            > -->
                        <v-select
                          id="subjectId"
                          v-model="userDto.subjectIds"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'ltr' : 'rtl'"
                          :options="subjectsList"
                          multiple
                          :reduce="(item) => item.id"
                        ></v-select>
                        <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
                      </b-form-group>
                    </b-col>
                    <b-col lg="1" md="6" sm="12" v-if="userDto.userType == 4">
                      <b-form-group
                        label="إمكانية الحسم"
                        label-for="detailsdiscountAvailable"
                      >
                        <b-form-checkbox
                          v-model="userDto.discountAvailable"
                          class="mr-0 mt-50"
                          id="detailsdiscountAvailable"
                          switch
                          inline
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
              <b-card-footer class="border-0 pt-0">
                <b-row>
                  <b-col lg="1" md="2" sm="12">
                    <b-button
                      @click.prevent="updateUserForm()"
                      size="md"
                      variant="primary"
                    >
                      تعديل
                    </b-button>
                  </b-col>
                  <b-col lg="1" md="2" sm="12">
                    <b-button
                      :to="`/user/${userDto.userType}`"
                      size="md"
                      variant="outline-primary"
                    >
                      تراجع
                    </b-button>
                  </b-col>
                  <b-col lg="1" md="2" sm="12">
                    <b-button
                      @click.prevent="deleteUserForm(userDto)"
                      size="md"
                      variant="danger"
                    >
                      حذف
                    </b-button>
                  </b-col>
                  <b-col
                    lg="1"
                    md="2"
                    sm="12"
                    v-if="
                      userDto.userType == 3 ||
                      userDto.userType == 4 ||
                      userDto.userType == 5
                    "
                  >
                    <b-button
                      @click="blockUser(userDto.id)"
                      size="md"
                      variant="outline-danger"
                    >
                      {{ userDto.dateBlocked == null ? "حظر" : "إلغاء الحظر" }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-card
      no-body
      v-if="
        userDto.userType == 2 ||
        userDto.userType == 3 ||
        userDto.userType == 4 ||
        userDto.userType == 5
      "
    >
      <b-card-body class="px-0 py-1">
        <userSecondaryTable :type="this.type" :userId="this.userId" />
      </b-card-body>
    </b-card>
  </div>
</template>
<style scoped></style>
<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email, no_spaces, english_letters_numbers } from "@validations";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";

import {
  BCard,
  BCardBody,
  BFormGroup,
  BFormInput,
  BCardFooter,
  BCardHeader,
  BRow,
  BCol,
  BButton,
  BForm,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import UserSecondaryTable from "./user-secondary-table.vue";
import vSelect from "vue-select";
export default {
  computed: {
    ...mapGetters([
      "userDto",
      "posCodesList",
      "responderVideoAnswersList",
      "studentCodesList",
      "teacherUnitsList",
      "subjectsList",
    ]),
  },
  created() {
    localize("ar");
    switch (+this.type) {
      case 1:
        this.fetchAdmin({ id: this.userId, type: 1 });
        break;
      case 2:
        this.fetchTeacherUnits(this.userId);
        this.getSubjectsList();
        break;
      case 3:
        this.fetchVideoAnswers(this.userId);
        break;
      case 4:
        this.fetchPosCodes(this.userId);
        break;
      case 5:
        this.fetchStudentCodes(this.userId);
        break;
    }
  },
  props: {
    type: String,
    userId: String,
  },
  watch: {
    statusAccount(data) {
      if (data) {
        this.userDto.dateBlocked = null;
      } else {
        this.userDto.dateBlocked = Date.now();
      }
    },
  },
  components: {
    BCard,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    BBadge,
    BFormCheckbox,
    UserSecondaryTable,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusAccount: true,
      required,
      email,
      no_spaces,
      english_letters_numbers,
      config: {
        allowInput: true,
      },
    };
  },
  methods: {
    ...mapActions([
      "fetchAdmin",
      "fetchTeacherUnits",
      "fetchVideoAnswers",
      "fetchPosCodes",
      "fetchStudentCodes",
      "updateUser",
      "removeUser",
      "changeBlocked",
      "getSubjectsList",
    ]),
    updateUserForm() {
      this.$refs.editAccountForm.validate().then((success) => {
        if (success) {
          this.updateUser({ user: this.userDto, vue: this });
        }
      });
    },
    deleteUserForm(user) {
      Swal.fire({
        title: "هل أنت متأكد من الحذف ؟",
        text: "مازال بإمكانك التراجع",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "حذف",
        cancelButtonText: "إلغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeUser(user);
        }
      });
    },
    blockUser(id) {
      this.changeBlocked(id);
    },
  },
};
</script>
